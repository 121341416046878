<template>
    <div class="tw-relative">
        <OverlayLoader v-if="loading.isLoading('interaction.create')" />
        <div class="tw-relative tw-max-h-[80vh] tw-w-80 tw-space-y-3 tw-overflow-y-auto tw-p-4">
            <WidgetSummary>
                {{ widget.click_to_call_introduction_text }}
            </WidgetSummary>
            <div class="tw-text-xs tw-text-mineShaft/60" v-if="areaName && privatePolicyLink">
                <p v-html="t('widget.live_privacy_policy', [areaName, privatePolicyLink])" />
            </div>
            <form
                @submit.prevent="
                    triedSubmission = true;
                    onSubmit();
                "
            >
                <MazPhoneNumberInput
                    class="tw-w-full"
                    @update="onPhoneUpdate"
                    orientation="col"
                    noCountrySelector
                    :label="t('widget.form.fields.phone_number')"
                    :translations="{
                        countrySelector: {
                            placeholder: 'Code pays',
                            error: 'Choisissez un pays',
                            searchPlaceholder: 'Rechercher un pays',
                        },
                        phoneInput: {
                            placeholder: 'Numéro de téléphone',
                            example: 'Example :',
                        },
                    }"
                />
                <p class="tw-mt-1 tw-text-sm tw-text-danger" v-if="submitCount > 0 ? errors['user_identity'] : undefined">
                    {{ errors['user_identity'] }}
                </p>
                <ApendayButton class="tw-mt-2 tw-w-full" type="submit" color="primary">
                    <IconPhone class="tw-mr-2 tw-w-7 tw-text-white" />
                    {{ t('widget.entity.enabledChannelsOptions.click_to_call') }}
                </ApendayButton>
                <input type="submit" hidden />
            </form>
        </div>
    </div>
</template>

<script setup lang="ts">
    import OverlayLoader from '@/components/live/OverlayLoader.vue';
    import WidgetSummary from '@/components/live/WidgetSummary.vue';

    import { useLoader } from '@/composables/wait';
    import { useInteractionStore } from '@/store/interaction';
    import { useField, useForm } from 'vee-validate';
    import { useRoute, useRouter } from 'vue-router';
    import { useWidgetStore } from '@/store/widget';
    import { storeToRefs } from 'pinia';
    import { computed, ref } from 'vue';
    import { toast } from '@/composables/toast';
    import { useI18n } from 'vue-i18n';
    import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';
    import ApendayButton from '@/components/ApendayButton.vue';
    import IconPhone from '@/components/icons/IconPhone.vue';

    const widgetStore = useWidgetStore();
    const { widget } = storeToRefs(widgetStore);
    const areaName = computed(() => widget.value.area.name);
    const privatePolicyLink = computed(() => {
        return widget.value.area?.live_privacy_policy_file?.content_url ?? widget.value.area?.live_privacy_policy_url;
    });
    const route = useRoute();
    const router = useRouter();
    const interactionStore = useInteractionStore();
    const { loading } = useLoader();
    const triedSubmission = ref(false);
    const { t } = useI18n();
    const isPhoneValid = ref(false);

    const { handleSubmit, errors, values, submitCount } = useForm({
        initialValues: {
            user_identity: '',
        },
    });

    useField('user_identity', () => {
        return !isPhoneValid.value ? t('validation.phoneNumber') : true;
    });

    function onPhoneUpdate($event) {
        values['user_identity'] = $event['e164'];
        isPhoneValid.value = $event['isValid'];
    }

    const onSubmit = handleSubmit(async (values) => {
        if (loading.isLoading('interaction.create')) return;
        try {
            await interactionStore.createClickToCall(values, route.params.id);
            router.replace({
                name: `click-to-call-end`,
            });
            loading.stop('interaction.create');
        } catch (e) {
            if (e.response?.status === 503) {
                localStorage?.setItem('show-unavailability-msg', 'true');
                // @ts-ignore
                window.location = '/' + route.params.id;
            } else {
                toast({
                    text: e.customErrorMsg ?? e.toString(),
                    type: 'error',
                });
                loading.stop('interaction.create');
            }
        }
    });
</script>
