<template>
    <div class="tw-w-80 tw-p-4">
        <p>{{ endingMessage }}</p>
        <form class="tw-mt-4 tw-space-y-5">
            <div class="tw-flex tw-space-x-2">
                <ApendayButton class="tw-w-full" type="button" color="primary" outline @click.prevent="$router.replace({ name: 'start' })">
                    {{ $t('actions.restart') }}
                </ApendayButton>
                <ApendayButton class="tw-w-full" type="button" color="primary" @click.prevent="$router.replace({ name: 'hidden' })"
                    >{{ $t('actions.close') }}
                </ApendayButton>
            </div>
        </form>
    </div>
</template>
<script setup lang="ts">
    import ApendayButton from '@/components/ApendayButton.vue';
    import { useInteractionStore } from '@/store/interaction';
    import { useWidgetStore } from '@/store/widget';
    import { storeToRefs } from 'pinia';
    import { computed, onMounted, onUnmounted } from 'vue';
    import { useI18n } from 'vue-i18n';
    import axios from '@/axios';
    import { useRouter } from 'vue-router';

    const widgetStore = useWidgetStore();
    const { widget } = storeToRefs(widgetStore);
    const interactionStore = useInteractionStore();
    const { interaction } = storeToRefs(interactionStore);
    const { t } = useI18n();
    const router = useRouter();

    const endingMessage = computed(() => {
        if (interaction.value?.channel === 'messaging') {
            return widget.value?.end_message_conversation ?? t('interaction.messagingView.end_message_placeholder');
        } else if (interaction.value?.channel === 'video') {
            return widget.value?.end_message_video ?? t('interaction.videoView.end_message_placeholder');
        } else {
            return widget.value?.click_to_call_confirmation_text ?? t('interaction.clickToCallView.end_message_placeholder');
        }
    });

    async function checkCallEnding() {
        const updatedInteraction = await axios.request<any, Interaction>({
            method: 'get',
            url: `api/live/${widget.value['id']}/${interaction.value['id']}`,
        });
        interactionStore.updateInteraction(updatedInteraction);
        if (updatedInteraction.status === 'closed') {
            router.replace({ name: 'start' });
        }
    }

    let intervalId = null;

    onMounted(() => {
        if (interaction.value?.channel === 'click_to_call') {
            intervalId = setInterval(checkCallEnding, 3000);
        }
    });

    onUnmounted(() => {
        clearInterval(intervalId);
    });
</script>
